<template>
  <div v-if="client" class="v-stack h-stretch">
    <div class="card light border h-stretch">
      <div class="content h-stretch">
        <form class="gap-3 h-stretch">
          <a class="heading-title-3">Info</a>
          <label class="text-left required">Name</label>
          <input type="text" v-model="client.name" />
          <label class="text-left">ICO</label>
          <input type="number" v-model="client.ico" />
          <label class="text-left">Website</label>
          <input type="text" v-model="client.website" />
          <label class="text-left">Description</label>
          <textarea v-model="client.description"></textarea>
          <a class="heading-title-3">Contact</a>
          <label class="text-left">Name</label>
          <input type="text" v-model="client.contactName" />
          <label class="text-left">Phone</label>
          <input type="text" v-model="client.contactPhone" />
          <label class="text-left">Email</label>
          <input type="email" v-model="client.contactEmail" />
          <div class="h-stack h-end">
            <button class="submit" @click.prevent="submit()">Submit</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      client: null,
    };
  },
  props: ["id"],
  methods: {
    ...mapActions(["editClient", "getClient"]),
    submit() {
      this.editClient(this.client)
        .then(() => {
          this.$router.go(-1);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getClient(this.id)
      .then((client) => {
        this.client = client;
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>